<template>
  <div class="p-1">
    <div class="flex justify-between items-center flex-wrap mb-2">
      <h3 class="text-2xl">Horas</h3>
      <a-button :to="{ name: 'create_classhour' }" v-if="!user.isStudent && !user.isLowAdmin" class="w-full md:w-auto">
        Crear hora de clase
      </a-button>
    </div>

    <a-table
      class="pb-4"
      :columns="columns"
      :source="pagination.data"
      :loading="loading"
      toolbar-column-filter
      :pagination="pagination"
      @paginate="search({ page: $event })"
      @toolbar(refresh)="search({ page: 1 })">

      <template #toolbar>
        <a-period-accessor v-model="query.period" class="mr-2" />
      </template>

      <template #td(actions)="{ item }">
        <a-dropdown>
          <template #menu>
            <a-dropdown-item :to="{ name: 'edit_classhour', params: { ...item } }">
              Actualizar
            </a-dropdown-item>
            <a-dropdown-item @click="deleteClasshour(item.id)">
              Eliminar
            </a-dropdown-item>
          </template>
        </a-dropdown>
      </template>

      <template #empty v-if="!query.period">
        <h1 class="text-2xl p-8">Para iniciar seleccione un <b>período</b></h1>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import hourTransform from "@/filters/hourTransform";
export default {
  data: () => ({
    query: {
      limit: 10,
      search: '',
      with: 'period',
      sort_by: 'start_hour',
      period: ''
    },
    timeout: null
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      pagination: state => state.classhours.pagination,
      loading: state => state.classhours.loading,
      periods: ({ periods }) => periods.all,
      loadingPeriods: ({ periods }) => periods.loading
    }),
    columns() {
      return [
        { title: 'Entrada', key: 'start', mutate: ({ item }, { timePadding }) => hourTransform(timePadding(item.start_hour), timePadding(item.start_minute)), display: true },
        // { title: 'Entrada', key: 'start', mutate: ({ item }, { timePadding }) => `${timePadding(item.start_hour)}:${timePadding(item.start_minute)}`, display: true },
        { title: 'Salida', key: 'end', mutate: ({ item }, { timePadding }) => hourTransform(timePadding(item.end_hour), timePadding(item.end_minute)), display: true },
        // { title: 'Salida', key: 'end', mutate: ({ item }, { timePadding }) => `${timePadding(item.end_hour)}:${timePadding(item.end_minute)}`, display: true },
        { title: 'Período', key: 'period', mutate: ({ key }) => `${key.year}-${key.number}`, display: true },
        { title: 'Acciones', slot: 'actions', key: 'actions', alignment: 'right', columnFilterable: false, display: this.user.isAdmin },
      ].filter(column => column.display)
    }
  },
  methods: {
    ...mapMutations({
      setNavbarTitle: 'SET_NAVBAR_TITLE'
    }),
    ...mapActions({
      fetchClasshours: 'classhours/index',
      deleteClasshour: 'classhours/delete',
      fetchPeriods: 'periods/index'
    }),
    handleTypingSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.fetchClasshours({ ...this.query, page: this.$route.query.page || 1 })
      }, 1000);
    },
    search(query) {
      this.fetchClasshours({ ...this.query, ...query })
    }
  },
  watch: {
    'query.period'(val) {
      this.fetchClasshours({ ...this.query, page: this.$route.query.page || 1, period: val })
    }
  },
  mounted() {
    this.fetchPeriods({ limit: 0, sort_by: 'year,asc' })
  }
}
</script>
