export default (hour, minutes) => {
    // let newHour = hour > 12 ? hour - 12 + ':' + minutes + 'pm' :
    let newHour = 0
    if (hour > 12) {
        newHour = hour - 12
        return newHour + ':' + minutes + ' p.m.'
    } else if (hour < 12) {
        return hour + ':' + minutes + ' a.m.'
    } else if (hour === 12) {
        return hour + ':' + minutes + ' p.m.'
    }
}
